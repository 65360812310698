import { useState } from "react";

import {
  Box, Container, Card, CardContent, CardHeader, Typography,
  Grid, Divider, Paper, Link,
  List, ListItem, ListSubheader, ListItemButton, ListItemIcon,
  Collapse, ListItemText, Toolbar
} from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import SocialProof from "./socialproof";

// import { Helmet } from 'react-helmet-async';

import { styled } from '@mui/system';
// import Logo from 'src/components/LogoSign';

import CapabilityCard from './capabilitycard';
import capabilitycardcontent from './capabilitycardcontent';

import { ThemeProvider } from '@mui/material'; //required to stop complaints about border radius

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


// const OverviewWrapper = styled(Box)(
//     () => `
//     overflow: auto;
//     flex: 1;
//     overflow-x: hidden;
//     align-items: center;
// `
// );

// {{ p: 10, mb: 10, borderRadius: 12 }}
function WhatWeDo() {
  return (
    <>
      <Paper
        sx={{
          // position: 'relative',
          backgroundColor: 'grey.800',
          color: '#fff',
          mb: 4,
          backgroundSize: 'cover',
          // width: "100%",
          minWidth: "280px"
          // backgroundRepeat: 'no-repeat',
          // backgroundPosition: 'center',
          // backgroundImage: `url(${post.image})`,
        }}
      >
        <Grid container spacing={4} paddingLeft={4} paddingRight={4} alignItems="stretch">
        <Grid item xs={12}>
                    <Typography sx={{textAlign: "center"}} variant="h4" component="h4">What we do</Typography>
                </Grid>

          {/* <Grid item xs={12}>
              <Box>
                <Typography sx={{ textAlign: 'center' }} component="h2" variant="h3">What we can do for you</Typography>
              </Box>
            </Grid> */}
          {/* <Typography sx={{textAlign: 'center'} } alignCenter component="h2" variant="h3">What we can do for you</Typography> */}
          {capabilitycardcontent.map((item, i) => (
            <CapabilityCard key={i} item={item}>
            </CapabilityCard>
          ))}
        </Grid>
        <Toolbar/>
        {/* </Box> */}
      </Paper>

    </>
  )
}

export default WhatWeDo;
