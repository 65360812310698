import * as React from 'react';
import ReactMarkdown from 'markdown-to-jsx';
// import ReactMarkdown from 'react-markdown';

import CodeBlock from './codeblock';
import RemarkMathPlugin from 'remark-math';
import { MathJax, InlineMathjax, MathJaxContext } from 'better-react-mathjax'
import ReactMathJaxContext from 'react-mathjax2';

import { Typography, Link, Box, Card, CardContent, CardMedia, Grid, CardHeader, Paper } from '@mui/material'

// import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { twilight } from "react-syntax-highlighter/dist/esm/styles/prism";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";

// import json from 'react-syntax-highlighter/dist/cjs/languages/prism/json';
// import cpp from 'react-syntax-highlighter/dist/cjs/languages/prism/cpp'
// import c from 'react-syntax-highlighter/dist/cjs/languages/prism/c'

import rehypeKatex from 'rehype-katex'
import rehypeRaw from "rehype-raw"; //allows html rendering

import { unified } from 'unified';
import markdown from 'remark-parse';
import math from 'remark-math';

import { renderToString } from 'react-dom/server'

// import { BlockMath, InlineMath } from 'react-katex'; // Assuming you're using the `react-katex` library for rendering math
// import 'katex/dist/katex.min.css'; // Optional: Import KaTeX CSS for styling


//https://blog.logrocket.com/guide-syntax-highlighting-react/
//https://www.commoninja.com/blog/how-to-build-a-code-syntax-highlighter-in-react#Building-a-Code-Syntax-Highlighter
//https://www.copycat.dev/blog/react-markdown/
//https://medium.com/@MatDrinksTea/rendering-markdown-and-latex-in-react-dec355e74119

function MarkdownListItem(props) {
  return <Box component="li" sx={{ mt: 1, typography: 'body1' }} {...props} />;
}





// SyntaxHighlighter.registerLanguage('json', json);
// SyntaxHighlighter.registerLanguage('C++', cpp);
// SyntaxHighlighter.registerLanguage('C', c);
// SyntaxHighlighter.registerLanguage('python', python);

function MathRenderer(props) {

  return <Box component="li" sx={{ mt: 1, typography: 'body1' }} {...props} />;
}


function Highlight(props) {

  // <Typography sx={{
  //   display: 'inline-block',
  //   // fontFamily: "Inconsolata, Monaco, Consolas, Courier New, Courier, monospace",
  //   fontFamily: "Inconsolata, Monaco, Consolas, Courier New, Courier, monospace",
  //   color: "#00AA00"
  // }}>
  return (
    <>
      <span style={{ color: "#AAAAAA", fontFamily: "Inconsolata, Monaco, Consolas, Courier New, Courier, monospace" }}>
        {props.children}
      </span>
    </>
  )

}


function CodeInTripleTicks(props) {
  // https://stackoverflow.com/questions/65807962/how-to-apply-code-highlights-within-markdown-to-jsx-package-in-react
  // console.log("triple ticks")
  // console.log(props.children.props)
  const internalProps = props.children.props
  let className = internalProps.className ? internalProps.className.replace('lang-', '') : null;
  // console.log(className)

  return (
    <>
      {
        className ?
          <SyntaxHighlighter language={className} style={okaidia} showLineNumbers>
            {internalProps.children}
          </SyntaxHighlighter>
          :
          <SyntaxHighlighter style={okaidia} showLineNumbers>
            {internalProps.children}
          </SyntaxHighlighter>
      }
    </>
  )

}

// <SyntaxHighlighter language={className} style={okaidia} showLineNumbers>
// {props.children}
// {/* <Box component="li" sx={{ mt: 1, typography: 'body1' }} {...props} />; */}
// {/* </SyntaxHighlighter> */}

// {/* <Box component="li" sx={{ mt: 1, typography: 'body1' }} {...props} />; */}

function Video(props) {

  // console.log({ video: props })

  return (
    <>
      <div style={{ textAlign: "center", background: "#FAFAFA", color: "#000000", padding: "10px", borderRadius: "4px" }}>
        <div style={{ maxWidth: "100%" }}>
          <video
            style={{ maxWidth: "100%", maxHeight: "450px" }}
            controls preload="metadata">
            <source src={props.src} type="video/mp4" />
            Your browser does not support video.
          </video>
          <div>
            {/* TEST CAPTION */}
            {props.children}
          </div>
        </div>
      </div>
      <br></br>
    </>
  )

}



function ArticleImage(props) {

  // console.log({ image: props })

  return (
    <>
      <div style={{ textAlign: "center", background: "#FAFAFA", color: "#000000", padding: "10px", borderRadius: "4px" }}>
        <img style={{ background: "#FFFFFF", maxWidth: "100%", maxHeight: "450px" }} src={props.src} alt={props.alt} />
        <Box>
          <div>
            {props.children}
          </div>
        </Box>
        {/* </p> */}
      </div>
      <br></br>
    </>
  )

}



function ArticleCard(props) {

  // https://stackoverflow.com/questions/51294471/material-ui-card-text-overlay
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{
            minHeight: "300px",
            display: 'flex',
            textAlign: "left",
            display: "flex", //TO VERTICALL ALIGN TEXT - https://stackoverflow.com/questions/56342496/vertical-align-using-material-ui-paper
            flexDirection: "column",  //vertical align text
            justifyContent: "center", //vertical align text,
            backgroundColor: "grey.800"
            // maxWidth: "100%"
          }}>
            <CardContent sx={{ flex: 1 }}>
              <Typography component="h2" variant="h5">
                {props.title}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" paragraph>
                {props.subhead}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" paragraph>
                {props.children}
              </Typography>
              <Paper sx={{ backgroundColor: "grey.400", padding: "10px", color: "#000000" }}>
                <div style={{ display: 'flex', justifyContent: 'center', width: "100%", maxHeight: "400px" }} >
                  {/* <div style={{ backgroundColor: "red", borderRadius: "80px", overflow: "hidden" }}>
                    <img style={{width: "100%", objectFit: "contain", height: "100%"}} src={props.img}></img> */}
                  <CardMedia
                    component="img"
                    sx={{
                      borderRadius: "4px",
                      width: "100%",
                      // height: "100%",
                      // maxHeight: "450px",
                      // width: "200px",
                      // backgroundSize: "cover",
                      justifyContent: "center",
                      textAlign: "center",
                      // borderRadius: "4px",
                      objectFit: "contain",
                      overflow: "hidden"
                      // display: { xs: 'none', sm: 'block' }
                    }}
                    image={props.img}
                    alt={props.alt}
                  />
                  {/* </div> */}
                </div>
                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center", paddingTop: "10px" }}>{props.caption}</Typography>
                </Grid>
              </Paper>

              {/* 
                <div style={{ textAlign: "center", backgroundColor: "#FFFFFF", color: "#000000", padding: "10px", borderRadius: "4px" }}>
                  <img style={{ width: "100%", background: "#FFFFFF", maxWidth: "100%", maxHeight: "450px", borderRadius: "4px" }} src={props.img} alt={props.alt} />
                  <Box>
                    asdasdasd
                    <div>
                      {props.caption}
                    </div>
                  </Box>
                </div> */}

            </CardContent>

            {/* <div style={{ textAlign: "center", background: "#00000000", color: "#000000", padding: "10px", borderRadius: "4px" }}>
          <img style={{ width: "75%", background: "#FFFFFF", maxWidth: "100%", maxHeight: "450px", borderRadius: "4px" }} src={props.img} alt={props.alt} />
          <Box>
            <div>
              {props.caption}
            </div>
          </Box>
        </div> */}
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

//<div style={{ textAlign: "center", background: "#FAFAFA", color: "#000000", padding: "10px", borderRadius: "4px" }}>
//<img style={{ background: "#FFFFFF", maxWidth: "100%", maxHeight: "450px" }} src={props.src} alt={props.alt} />
//<Box>
//  <div>
//    {props.children}
//  </div>
//</Box>
//{/* </p> */}
//</div>
/* <CardMedia
  component="img"
  sx={{
    width: 160,
    display: { xs: 'none', sm: 'block' }
  }}
  image={data.image}
  alt={data.imageLabel}
/> */
// /* <Typography variant="subtitle1" color="text.secondary" paragraph>
//   {data.subhead}
// </Typography> */}
// /* {data.subhead.map((item, i) => (
//   <Typography key={i} variant="subtitle1" color="text.secondary" paragraph>
//     {item}
//   </Typography> */

// {/* <Typography variant="subtitle1" paragraph>
//   {data.description}
// </Typography> */


const options = {
  plugins: [
    RemarkMathPlugin,
  ],
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h5',
        component: 'h1',
      },
    },
    h2: {
      component: Typography,
      props: { gutterBottom: true, variant: 'h6', component: 'h2' },
    },
    h3: {
      component: Typography,
      props: { gutterBottom: true, variant: 'subtitle1' },
    },
    h4: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'caption',
        paragraph: true,
      },
    },
    p: {
      component: Typography,
      props: { paragraph: true },
    },
    a: { component: Link },
    li: {
      component: MarkdownListItem,
    },
    // code: {component: Highlight },
    // inline: {component: Typography },
    // articlevideo: {component: Video }, //add video with a special tag... <video src="file"></video>
    // articleimage: {component: ArticleImage } //you can jsut make special tags! yay!
    code: { component: Highlight },
    pre: { component: CodeInTripleTicks },
    articlevideo: { component: Video }, //add video with a special tag... <video src="file"></video>
    articleimage: { component: ArticleImage }, //you can jsut make special tags! yay!
    publicationarticlecard: { component: ArticleCard }
  },
};

function Blank(props) {
  console.log("DROPPING AN ELEMENT")
  console.log(props)

  return (<></>)
}

// https://stackoverflow.com/questions/75358080/how-can-i-embed-a-youtube-video-in-reactjs-markdown-with-react-markdown

export default function Markdown(props) {
  let content = props.children;

  const processedContent = content.replace(/\$(?![\$])(.*?)\$/g, (_, mathBlock) => {
    return "\\\\(" + mathBlock + "\\\\)";
  });



  const newProps = {
    ...props,
    children: processedContent
    // plugins: [
    //   RemarkMathPlugin,
    // ],
    // escapeHtml: true,
    // renderers: {
    //   inlineMath: renderInlineMath
    // }
    // renderers: {
    //   ...props.renderers,
    //   // math: (props) => {
    //     // console.log("CRYMSBS");
    //     // console.log(props);
    //     // return <Typography>ASDLSD</Typography>
    //   // }
    //   // <MathJax.Node formula={props.value} />,
    //   math: (props) => <MathJax><Typography>FUCK</Typography></MathJax>,
    //   inlineMath: (props) => <MathJax>{props.value}</MathJax>
    //   // <MathJax.Node inline formula={props.value} />
    // }
  }

  // console.log(props)


  return (
    <MathJaxContext>
      <ReactMarkdown
        options={options}
        {...newProps}
      >
      </ReactMarkdown>
    </MathJaxContext>
  )
}