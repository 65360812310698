import { createSlice } from '@reduxjs/toolkit'


import steamengine from '../content/blog/articles/steam-engine.js'
import mathsmap from '../content/blog/articles/maths-map.js'
import rosehipsag from '../content/blog/articles/rosehips-ag.js'
import marchingcubes from '../content/blog/articles/marching-cubes.js'
import isf2023 from '../content/blog/articles/isf-2023-accepted.js'
import gamm2023 from '../content/blog/articles/gamm-2023-accepted.js'

//key is the url
const articles = {
    // article1: {
    //     title: "article one",
    //     subhead: "words",
    //     image: "/logo192.png",
    //     imageLabel: "alt text",
    //     link: "/news/article1",
    //     file: test1
    // },
    // article2: {
    //     title: "article two",
    //     subhead: "words",
    //     image: "/logo192.png",
    //     imageLabel: "alt text",
    //     link: "/news/article2",
    //     file: test1
    // },
}


articles[gamm2023.unique] = gamm2023;
articles[isf2023.unique] = isf2023;
articles[marchingcubes.unique] = marchingcubes;
articles[steamengine.unique] = steamengine;
articles[mathsmap.unique] = mathsmap;
articles[rosehipsag.unique] = rosehipsag;

export const blogSlice = createSlice({
  name: 'blog',
  initialState: {
    articles: articles,
  },
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes.
    //   // Also, no return statement is required from these functions.
    //   state.value += 1
    // },
    // decrement: (state) => {
    //   state.value -= 1
    // },
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload
    // },
  },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions
export const { } = blogSlice.actions


export const selectBlogs = (state) => {
    // console.log("WHAT")
    // console.log(state.blog)
    //state.nameofslice.actualdatahere
    return state.blog.articles;
}


export const selectArticle = (state, url) => {
    // console.log("WHAT")
    // console.log(state.blog)
    //state.nameofslice.actualdatahere
    return state.blog.articles[url];
}

export default blogSlice.reducer


