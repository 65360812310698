import isfaccepted from './isf-2023-accepted.md'

const data = {
    unique: "isf-2023-accepted",
    title: "Accepted to the ISF2023 Conference",
    subhead: "Bayesian forecasting for decision making",
    image: "/images/2023/isf-2023-accepted/charlottesville-1200x675.jpg",
    imageThumb: "/images/2023/isf-2023-accepted/iifbanner-retina-900.png",
    imageLabel: "ISF 2023 accepted article",
    link: "/news/isf-2023-accepted",
    file: isfaccepted
}



export default data;