import { React, useState } from 'react';
import {
    Box, AppBar, Toolbar, Typography, Divider, List, ListItem, ListItemButton,
Drawer, Container, Button, ListItemText, IconButton } from "@mui/material"

import MenuIcon from '@mui/icons-material/Menu';

import { Link } from "react-router-dom";


const drawerWidth = 240;
const navItems = [
    {
        text: 'Home',
        link: '/'
    },
    {
        text: 'News',
        link: '/news'
    },
    {
        text: 'Portfolio',
        link: '/portfolio'
    },
    // {
    //     text: 'Services',
    //     link: '/services'
    // },
    {
        text: 'Academia',
        link: '/academia'
    },
    {
        text: 'About',
        link: '/about'
    },
    {
        text: 'Contact',
        link: '/contact'
    }];



function TopNav(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);


    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const container = window !== undefined ? () => window().document.body : undefined;


    // This is the actual drawer that pops out
    const drawer = (
        <>
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                DKGR Data Systems
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.text} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }} component={Link} to={item.link}>
                            <ListItemText primary={item.text}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            </Box>
            <Toolbar/> 
            </>
    );

    return (
        <>
        <Box sx={{ display: 'flex' }}>
            {/* Sticky to make it appear aboev stuff */}
            <AppBar component="nav" position="fixed">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{
                            mr: 2,
                            display: { md: 'none' } //none here hides the menu clicker when >= size md
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}
                    >
                        DKGR Data Systems
                    </Typography>
                        <Box sx={{ display: { xs: 'none', md: 'block' } }}> { /* CONTAINS THESTANDARD NAV BAR BUTTONS - https://stackoverflow.com/questions/47206639/how-to-add-a-link-to-a-list-in-material-ui-1-0 */}
                        {navItems.map((item) => (
                            <Button key={item.text} component={Link} to={item.link} sx={{ color: '#fff' }}>
                                {item.text}
                            </Button>
                        ))}
                    </Box>

                        {/* this is the one when small */}
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { md: 'none' } }}
                        onClick={handleDrawerToggle}
                    >
                        DKGR Data Systems
                    </Typography>

                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
        <Toolbar/>  {/* https://stackoverflow.com/questions/48508449/content-beneath-fixed-appbar */}
        </>
    )
}

export default TopNav;
