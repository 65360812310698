import AcUnit from '@mui/icons-material/AcUnit'
import CodeIcon from '@mui/icons-material/Code';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

import EngineeringIcon from '@mui/icons-material/Engineering';

import TimelineIcon from '@mui/icons-material/Timeline';
import WatchOutlinedIcon from '@mui/icons-material/WatchOutlined';
import WatchIcon from '@mui/icons-material/Watch';
import ScienceIcon from '@mui/icons-material/Science';
import PsychologyIcon from '@mui/icons-material/Psychology';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import ComputerIcon from '@mui/icons-material/Computer';

import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import FunctionsIcon from '@mui/icons-material/Functions';

import DataObjectIcon from '@mui/icons-material/DataObject';
import CasinoIcon from '@mui/icons-material/Casino';
const iconFontSize = 100;
const iconStyle = { fontSize: iconFontSize };
// const iconStyle = {minWidth: "100%" };

const cardcontents = [
    {
        title: "Programming",
        subhead: ["C/C++, Python, Javscript, C#, GLSL, SQL, Node, Unity & More", "High performance computing & 3D rendering"],
        description: "C/C++, Python, Javascript",
        image: "https://source.unsplash.com/random?wallpapers",
        imageLabel: "Programming",
        icon: <CodeIcon sx={iconStyle}/>
    },
    {
        title: "Artificial Intelligence",
        subhead: ["Research and commercial applications of Artificial Intelligence", "Reinforcement learning, neural networks, LLMs"],
        description: "get outta town",
        image: "https://source.unsplash.com/random?wallpapers",
        imageLabel: "Artificial Intelligence",
        icon: <PsychologyIcon sx={iconStyle}/>
    },
    {
        title: "Management",
        subhead: ["Product-focussed strategy and roadmap development", "Effective team leadership for product delivery"],
        description: "get outta town",
        image: "https://source.unsplash.com/random?wallpapers",
        imageLabel: "Management",
        icon: <InsertEmoticonIcon sx={iconStyle}/>
    },
    {
        title: "Time Series Analysis",
        subhead: ["Anomaly detection, forecasting and predictive analytics", "Big data, cloud, edge compute and web APIs"],
        description: "get outta town",
        image: "https://source.unsplash.com/random?wallpapers",
        imageLabel: "Time Series",
        icon: <TimelineIcon sx={iconStyle}/>
    },
    {
        title: "Web App Design",
        subhead: ["User interfaces and dashboards to work with data interactively", "SaaS service development and deployment"],
        description: "get outta town",
        image: "https://source.unsplash.com/random?wallpapers",
        imageLabel: "Web App Design",
        icon: <DashboardCustomizeIcon sx={iconStyle}/>
    },
    
    {
        title: "R&D",
        subhead: ["Proof of concept development and implementation", "Deep technical background ensures delivery"],
        description: "get outta town",
        image: "https://source.unsplash.com/random?wallpapers",
        imageLabel: "R&D",
        icon: <ScienceIcon sx={iconStyle}/>
    },
    {
        title: "Civil and Geotechincal Engineering",
        subhead: ["Advanced knowledge of the civil engineering domain", "Excavations, tunnels and foundations"],
        description: "get outta town",
        image: "https://source.unsplash.com/random?wallpapers",
        imageLabel: "Civil and Geotechincal Engineering",
        icon: <EngineeringIcon sx={iconStyle}/>
    },
    {
        title: "Wearables and Embedded Systems",
        subhead: ["High performance embedded code and RTOS systems", "Hardware development for sensors"],
        description: "get outta town",
        image: "https://source.unsplash.com/random?wallpapers",
        imageLabel: "Wearables and Embedded Systems",
        icon: <WatchOutlinedIcon sx={iconStyle}/>
    },
    {
        title: "Real time and engineering simulation",
        subhead: ["Real-time simulation including game development", "Physical simulation - FEM, FVM"],
        description: "get outta town",
        image: "https://source.unsplash.com/random?wallpapers",
        imageLabel: ["Physical Simulation"],
        icon: <ComputerIcon sx={iconStyle}/>
    },
    {
        title: "Advanced Mathematics",
        subhead: ["PhD and research level mathematics", "Productionisation of research mathematics into code"],
        description: "get outta town",
        image: "https://source.unsplash.com/random?wallpapers",
        imageLabel: "Advanced Mathematics",
        icon: <FunctionsIcon sx={iconStyle}/>
    },
    {
        title: "Bayesian Probability",
        subhead: ["Including information theory, measure theory, Gaussian processes", "Risk and decision making theory"],
        description: "get outta town",
        image: "https://source.unsplash.com/random?wallpapers",
        imageLabel: "Bayesian Probability",
        icon: <CasinoIcon sx={iconStyle}/>
    },
    {
        title: "Data Science & Machine Learning",
        subhead: ["From Proof-of-Concept to MVP", "Production model development"],
        description: "get outta town",
        image: "https://source.unsplash.com/random?wallpapers",
        imageLabel: "Data Science & Machine Learning",
        icon: <DataObjectIcon sx={iconStyle}/>
    }
];



export default cardcontents;