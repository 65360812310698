import { React, useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import {
    Box, AppBar, Typography, IconButton, Toolbar,
    Divider, ListItem, List, ListItemButton, ListItemText, Button, Drawer, CssBaseline,
    Grid
} from '@mui/material';
// import { MenuIcon } from '@mui/icons-material'

import TopNav from './BaseLayout/SidebarLayout/Header/TopNav';

function BaseLayout(props) {



    // sx={{ flexGrow: 1 }}
    // https://mui.com/material-ui/customization/breakpoints/
    return (
        <>
            {/* <Toolbar /> https://stackoverflow.com/questions/48508449/content-beneath-fixed-appbar */}
            <Box>
                <Box >
                    {/* <br /> */}
                    <Grid container spacing={2}>
                        <Grid item xs={1} md={2}>
                        </Grid>
                        <Grid item xs={10} md={8}>
                            {props.children}
                        </Grid>
                        <Grid item xs={1} md={2}>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
        //   sx={{
        //     flex: 1,
        //     height: '100%'
        //   }}
        // >
        //   {children || <Outlet />}
        // </Box >
    );
};


export default BaseLayout;
