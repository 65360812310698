import timeseriesForecastingAnomaly from './time-series-forecasting-anomaly.md'

const data = {
    unique: "time-series-forecasting-anomaly",
    title: "Time series analysis: forecasting and anomaly detection",
    subhead: "Using statistical models to automate data interpretation",
    image: "/images/portfolio/time-series-forecasting-anomaly/dsanomaly.png",    
    imageThumb: "/images/portfolio/time-series-forecasting-anomaly/dsanomalythumb.png",
    imageLabel: "Simulated fall dataarticle",
    link: "/portfolio/time-series-forecasting-anomaly",
    file: timeseriesForecastingAnomaly
}

export default data;