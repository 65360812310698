import roboticInspection from './robotic-inspection.md'

const data = {
    unique: "robotic-inspection",
    title: "Computer vision and software delivery for an inspection robot",
    subhead: "Image processing and viewing software development for a hardware team",
    image: "/images/portfolio/robotic-inspection/mainrobot.png",    
    imageThumb: "/images/portfolio/robotic-inspection/defectannotation.png",
    imageLabel: "Robotic inspection article",
    link: "/portfolio/robotic-inspection",
    file: roboticInspection
}

export default data;