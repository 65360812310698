import { Toolbar, Typography, Grid, Box } from "@mui/material";
import BigFeaturedPost from "../../components/bigfeaturedpost";

import WarningIcon from '@mui/icons-material/Warning';

function FourZeroFour() {
    const content = {
        title: "404 Error",
        subhead: "Page not found!",
        icon: <WarningIcon />,
        image: "/logo192.png",
        imageLabel: "404 Error image",
        link: "/",
        file: ""
    }

    return (
        <>
            <Toolbar></Toolbar>
            <Box>
                <Grid container justifyContent="center" alignItems="center" direction="column">
                    <Grid item xs={12}>
                        <WarningIcon sx={{ fontSize: "200px" }} />
                    </Grid>
                    {/* <BigFeaturedPost content={content}></BigFeaturedPost> */}
                    <Grid item xs={12}>
                        <Typography>Page not found!</Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default FourZeroFour;