import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { duotoneForest } from "react-syntax-highlighter/dist/esm/styles/prism";

// https://github.com/react-syntax-highlighter/react-syntax-highlighter/blob/master/AVAILABLE_STYLES_PRISM.MD

// export { default as a11yDark } from 'react-syntax-highlighter/dist/cjs/styles/prism/a11y-dark';
// export { default as atomDark } from 'react-syntax-highlighter/dist/cjs/styles/prism/atom-dark';
// export { default as base16AteliersulphurpoolLight } from 'react-syntax-highlighter/dist/cjs/styles/prism/base16-ateliersulphurpool.light';
// export { default as cb } from 'react-syntax-highlighter/dist/cjs/styles/prism/cb';
// export { default as coldarkCold } from 'react-syntax-highlighter/dist/cjs/styles/prism/coldark-cold';
// export { default as coldarkDark } from 'react-syntax-highlighter/dist/cjs/styles/prism/coldark-dark';
// export { default as coy } from 'react-syntax-highlighter/dist/cjs/styles/prism/coy';
// export { default as darcula } from 'react-syntax-highlighter/dist/cjs/styles/prism/darcula';
// export { default as dark } from 'react-syntax-highlighter/dist/cjs/styles/prism/dark';
// export { default as dracula } from 'react-syntax-highlighter/dist/cjs/styles/prism/dracula';
// export { default as duotoneDark } from 'react-syntax-highlighter/dist/cjs/styles/prism/duotone-dark';
// export { default as duotoneEarth } from 'react-syntax-highlighter/dist/cjs/styles/prism/duotone-earth';
// export { default as duotoneForest } from 'react-syntax-highlighter/dist/cjs/styles/prism/duotone-forest';

// import { coy } from "react-syntax-highlighter/dist/styles/prism";
// import  {coy}

// https://medium.com/young-developer/react-markdown-code-and-syntax-highlighting-632d2f9b4ada
// https://amirardalan.com/blog/syntax-highlight-code-in-markdown

import json from 'react-syntax-highlighter/dist/cjs/languages/prism/json';
import { Typography } from "@mui/material";

SyntaxHighlighter.registerLanguage('json', json);


class CodeBlock extends PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    language: PropTypes.string
  };

  static defaultProps = {
    language: null
  };


    render() {
      console.log("LANGUGAGE: ")
      console.log(language)
    const { language, value } = this.props;
        return (
        <Typography>ASADASD</Typography>
    //   <SyntaxHighlighter language={language} style={duotoneForest}>
    //     {value}
    //   </SyntaxHighlighter>
    );
  }
}

export default CodeBlock;