
import { Box, Container, Card, CardContent, CardHeader, Typography, Grid, Divider, Paper, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import Markdown from './markdown'

function PostContent(props) {
    const content = props.content;
    // const post = {
    //     title: "About",
    //     description: "DKGR Data Systems is a London-based technology studio",
    //     linkText: "Contact us",
    //     image: "https://source.unsplash.com/random?wallpapers"
    // }

    const [fileContentsValue, setFileContentsValue] = useState("");

    useEffect(() => {
        // Update the document title using the browser API

        fetch(content).then((response) => response.text()).then((text) => {
            setFileContentsValue(text)
            // console.log(text)
        }).catch((err) => {
            console.log("ERROR LOADING ARTICLE")
            console.log(err)
        })
        //document.title = `You clicked ${count} times`;
    });

    return (
        <Paper>
                <Box padding={4}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            {
                                fileContentsValue === "" ?
                                <>
                                    {/* <Typography>Loading...</Typography> */}
                                    </> :
                                    <Markdown className="markdown" key={fileContentsValue.substring(0, 40)}>
                                        {fileContentsValue}
                                    </Markdown>
                            }

                            {/* <Typography sx={{textAlign: 'center'} } alignCenter component="h2" variant="h3">CONTENT</Typography> */}
                            {/* <Typography sx={{ textAlign: 'left' }} alignCenter component="h4" variant="h4">CONTENT</Typography> */}
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Typography>HERE IS SOME CONTENT</Typography>
                        </Grid> */}
                        {/* {capabilitycardcontent.map((item) => (
              <CapabilityCard key={item.name} item={item}>
              </CapabilityCard>
            ))} */}
                    </Grid>
                </Box>
            </Paper>
    )
}

export default PostContent;
