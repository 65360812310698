import { configureStore } from '@reduxjs/toolkit'

import blogReducer from './blogSlice'
import portfolioReducer from './portfolioSlice'


export default configureStore({
    reducer: {
      blog: blogReducer,
      portfolio: portfolioReducer
  },
})