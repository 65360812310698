import metabaseDataAccess from './metabase-data-access.md'

const data = {
    unique: "metabase-data-access",
    title: "Making production data accessable",
    subhead: "Using Metabase to provide insights into a production database",
    image: "/images/portfolio/metabase-data-access/metabase2.png",
    imageThumb: "/images/portfolio/metabase-data-access/illustration_make_data_easy.png",
    imageLabel: "Metabase article",
    link: "/portfolio/metabase-data-access",
    file: metabaseDataAccess
}

export default data;