import { Box, Typography, Grid, Toolbar } from '@mui/material';
// import { Helmet } from 'react-helmet-async';

import { useLocation } from 'react-router-dom';

import { selectAllPortfolioItems, selectPortfolio } from '../../data/portfolioSlice';

import { useSelector, useDispatch } from 'react-redux'

import SmallFeaturedPost from '../../components/smallfeaturedpost';
import DisplayArticle from '../../components/displayarticle';
import DisplayArticleCard from '../../components/displayarticlecard';

import PageHeader from '../../components/pageheader'



function DisplayAllArticles() {
    const articles = useSelector(selectAllPortfolioItems)

    return (
        <>
            <Grid container spacing={4}>

                {
                    articles ? Object.keys(articles).map((item, index) => {
                        const post = articles[item]
                        // return <PostContent content={item.file}></PostContent>
                        return (
                            <DisplayArticleCard key={index} content={post}></DisplayArticleCard>
                        )
                    }) : <> <Typography>Loading...</Typography></>
                }
            </Grid>
        </>
    )
}





function PortfolioLayout(props) {

    const post = {
        title: "Portfolio",
        description: "A small selection of some of our projects",
        linkText: "Contact us",
        // image: "https://source.unsplash.com/random?wallpapers"
        image: "images/bg.jpg"
    }


    const location = useLocation();

    const parsedLocation = location.pathname.split("/");

    let articleid = "";
    let noArticleID = true;

    if (parsedLocation.length === 2) {
        //there is no article id
    } else if (parsedLocation.length === 3) {
        articleid = parsedLocation.pop();
        noArticleID = false;
    }

    const article = useSelector(state => {
        if (!noArticleID) {
            return state.portfolio.articles[articleid];
        } else {
            return state.portfolio.articles["article1"];;
        }
    })


    return (
        <>
            {/* <Toolbar /> */}
            <Box>
                <Box>
                    <>{noArticleID ?
                        <>
                            {
                                props.noFeature ?
                                    <>
                                        {/* <Box>
                                            <Typography component="h1" variant="h3" color="inherit" sx={{ textAlign: "center" }} gutterBottom>
                                                Some of our work
                                            </Typography>
                                        </Box> */}
                                        <PageHeader post={post} heroImageHeight={props.heroImageHeight}></PageHeader>
                                    </> :
                                    <>
                                        <PageHeader post={post}></PageHeader>
                                    </>
                            }
                            {/* <BigFeaturedPost content={article} /> */}
                            <DisplayAllArticles />
                        </> :
                        <>
                            <SmallFeaturedPost content={article}></SmallFeaturedPost>
                            <DisplayArticle content={article}></DisplayArticle>
                            <Toolbar />
                            <Typography variant="h4" component="h4">More portfolio items...</Typography>
                            <DisplayAllArticles />
                        </>
                    }
                    </>
                </Box>
            </Box>
        </>
    );
}



export default PortfolioLayout;
