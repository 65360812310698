import { React } from 'react';

import {
    Card, CardContent, CardMedia, Typography, Paper, Grid
} from '@mui/material';


// const useStyles = makeStyles((theme) => ({
//     logoContainer: {
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         height: 100,
//     },
//     logoImage: {
//         width: 'auto',
//         height: '100%',
//         maxWidth: '100%',
//     },
// }));

const SocialProof = () => {
    // const classes = useStyles();

    const companyLogos = [
        { src: '/images/prooflogo/DYWIDAG-LOGO-Seaweed.svg', alt: 'DYWIDAG' },
        { src: '/images/prooflogo/ukri-epsrc-standard-logo.png', alt: 'ESPRC' },
        { src: '/images/prooflogo/psm.png', alt: 'PSM' },
        { src: '/images/prooflogo/Alan_Turing_Institute_logo.svg.png', alt: 'ATI' },
        { src: '/images/prooflogo/tu_braunschweig_logo.svg', alt: 'TU Braunschweig' },
        { src: '/images/prooflogo/rosehips-logo.png', alt: 'ROSEHIPS' },
        { src: '/images/prooflogo/Railcorp.svg', alt: 'Railcorp' },
        { src: '/images/prooflogo/II-Primary-DYWI-ForOnDark.svg', alt: 'II' },
        { src: '/images/prooflogo/warwick.jpg', alt: 'Warwick' },
        { src: '/images/prooflogo/unsw_0.png', alt: 'UNSW' },
        { src: '/images/prooflogo/singularity_logo.png', alt: 'Singularity' },
        { src: '/images/prooflogo/lrf.png', alt: 'LRF' },
        { src: '/images/prooflogo/Transurban_logo.svg', alt: 'Transurban' },
        { src: '/images/prooflogo/laingorourke.png', alt: "Laing O'Rourke" },
        { src: '/images/prooflogo/ingpuls-gmbh-logo-svg.svg', alt: 'Ingpuls' },
        { src: '/images/prooflogo/Network_Rail_logo.svg', alt: 'Network Rail' },
        { src: '/images/prooflogo/tuberlin.svg', alt: 'TU Berlin' },
        { src: '/images/prooflogo/ghib.png', alt: 'Gordie Hower International Bridge' },
        // { src: '/images/prooflogo/laingorourke.png', alt: "Laing O'Rourke" },





        // Add more company logos as needed
    ];

    return (
        // <Paper>
        <Paper
            sx={{
                // position: 'relative',
                backgroundColor: 'grey.800',
                // backgroundColor: '#ffffff00',
                color: '#fff',
                //   mb: 4,
                backgroundSize: 'cover',
                // width: "100%",
                minWidth: "280px"
                // backgroundRepeat: 'no-repeat',
                // backgroundPosition: 'center',
                // backgroundImage: `url(${post.image})`,
            }}
        >
            <Grid container spacing={1} paddingLeft={2} paddingRight={2}>

                <Grid item xs={12}>
                    <Typography sx={{textAlign: "center"}} variant="h4" component="h4">Trusted by</Typography>
                </Grid>


                {/* Section Heading */}
                {/* <Typography variant="h4" gutterBottom>
                    Social Proof Section
                </Typography> */}


                {/* Company Logos Grid */}
                {companyLogos.map((item, i) => (
                    <Grid key={i} item xs={4} md={4} lg={2}>
                        <Card sx={{
                            minHeight: 50,
                            display: 'flex',
                            textAlign: "center",
                            display: "flex", //TO VERTICALL ALIGN TEXT - https://stackoverflow.com/questions/56342496/vertical-align-using-material-ui-paper
                            flexDirection: "column",  //vertical align text
                            justifyContent: "center", //vertical align text,
                            backgroundColor: 'grey.600'
                            // maxWidth: "100%"

                        }}>
                            <CardContent sx={{ flex: 1 }}>
                                <CardMedia

                                    sx={{
                                        // height: 75,
                                        minHeight: 50,
                                        // width: "100%",
                                        height: "100%",
                                        filter: "grayscale(85%)",
                                        backgroundSize: "contain"
                                    }}
                                    image={item.src}
                                    title={item.alt}
                                >
                                </CardMedia>
                                {/* hey */}
                            </CardContent>
                            {/* <CardMedia
            component="img"
            sx={{
              width: 160,
              display: { xs: 'none', sm: 'block' }
            }}
            image={data.image}
            alt={data.imageLabel}
          /> */}
                        </Card>
                    </Grid>
                ))
                }


            </Grid>
            <br />
        </Paper>

    );
};

export default SocialProof;