import { Typography, Grid, Card, CardActionArea, CardContent, CardMedia } from '@mui/material';



function DisplayArticleCard(props) {
    const content = props.content;

    let displaySubhead = content.subhead;
    if(displaySubhead.length > 63)
    {
        displaySubhead = displaySubhead.substring(0, 60) + "...";
        // console.log({displaySubhead: displaySubhead})
    }

    return (
        <>
            {
                content ?
                    <>
                        <Grid item xs={12} md={6}>
                            <CardActionArea sx={{ display: { xs: 'none', sm: 'block' }}} component="a" href={content.link}>
                                <Card sx={{ display: 'flex', height:"250px" }}>
                                    <CardContent sx={{ flex: 1 }}>
                                        <Typography component="h3" variant="h6">
                                            {content.title}
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary">
                                            {displaySubhead}
                                        </Typography>
                                        {/* <Typography variant="subtitle1" paragraph>
                                            {content.description}
                                        </Typography> */}
                                        <Typography variant="subtitle1" color="primary">
                                            Continue reading...
                                        </Typography>
                                    </CardContent>
                                    <CardMedia
                                        component="img"
                                        // sx={{ width: { xs: 160, md: 160, xl: 400 }, display: { xs: 'none', sm: 'block' } }}
                                        sx={{ width: { xs: "33%" }, display: { xs: 'none', sm: 'block' } }}
                                        image={content.imageThumb}
                                        alt={content.imageLabel}
                                    />
                                </Card>
                            </CardActionArea>

                            <CardActionArea sx={{ display: { sm: 'none' }}} component="a" href={content.link}>
                                <Card sx={{  }}>
                                    {/* <CardContent sx={{ flex: 1 }}> */}
                                        {/* <Typography component="h3" variant="h6">
                                            {content.title}
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary">
                                            {displaySubhead}
                                        </Typography> */}
                                        {/* <Typography variant="subtitle1" paragraph>
                                            {content.description}
                                        </Typography> */}
                                        {/* <Typography variant="subtitle1" color="primary">
                                            Continue reading...
                                        </Typography> */}
                                    {/* </CardContent> */}
                                    <CardMedia
                                        component="img"
                                        sx={{ width: "100%", maxHeight: "250px" }}
                                        image={content.imageThumb}
                                        alt={content.imageLabel}
                                    />
                                    <CardContent>
                                        <Typography component="h3" variant="h6">
                                            {content.title}
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary">
                                            {displaySubhead}
                                        </Typography>
                                        {/* <Typography variant="subtitle1" paragraph>
                                            {content.description}
                                        </Typography> */}
                                        <Typography variant="subtitle1" color="primary">
                                            Continue reading...
                                        </Typography>                                       </CardContent>
                                </Card>
                            </CardActionArea>


                        </Grid>
                    </>
                    :
                    <> <Typography>Loading...</Typography></>
            }
        </>
    )
}
export default DisplayArticleCard