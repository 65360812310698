import { Box, Container, Card, CardContent, CardHeader, Typography, Grid, Divider, Paper, Link, Toolbar } from '@mui/material';
// import { Helmet } from 'react-helmet-async';

import { styled } from '@mui/system';
// import Logo from 'src/components/LogoSign';

import { ThemeProvider } from '@mui/material'; //required to stop complaints about border radius

import { useLocation } from 'react-router-dom';

import PageHeader from '../../components/pageheader'
import PostContent from '../../components/postcontent'

import abouttext from './abouttext.md'
import { useEffect, useState } from 'react';
import Markdown from '../../components/markdown'


function AboutLayout() {


    const post = {
        title: "About",
        description: "DKGR Data Systems is a London-based technology studio run by Dr. David K. E. Green",
        linkText: "Contact us",
        image: "images/bg.jpg"
        // image: "https://source.unsplash.com/random?wallpapers"
    }




    return (
        <>
            <Toolbar></Toolbar>
            <PageHeader post={post} />
            <PostContent content={abouttext}/>
        </>
    );
}


//     <Box display="flex" justifyContent="center" py={5} alignItems="center">
//       {/* <Logo /> */}
//     </Box>
//     {/* <Card sx={{ p: 10, mb: 10, borderRadius: 12 }}> */}
//     <Card sx={{ p: 10, mb: 10 }}>
//       {/* <Hero /> */}
//     </Card>

export default AboutLayout;
