import { Typography, Grid } from '@mui/material';

import PostContent from './postcontent';

function DisplayArticle(props) {
    const content = props.content;

    return (
        <>
            <Grid item xs={12}>
                {
                    content ?
                        <>
                            <PostContent content={content.file} />
                        </> :
                        <>
                            <Typography>Loading...</Typography>
                        </>
                }
            </Grid>

        </>
    )
}

export default DisplayArticle