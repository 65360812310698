
import {
    createBrowserRouter,
    RouterProvider,
    Route,
} from "react-router-dom";


import Root from "./routes/root";
import BaseLayout from "./layouts/baselayout";
import Main from "./content/landing/landing";
import Footer from "./layouts/footer"

import App from "./App";
import { Toolbar, Typography, Grid, Paper } from "@mui/material";
import TopNav from "./layouts/BaseLayout/SidebarLayout/Header/TopNav";
import HeroLayout from "./content/landing/herolayout";

import BlogLayout from "./content/blog/blog"
import AboutLayout from "./content/about/about"
import ContactLayout from "./content/contact/contact"

import FourZeroFour from "./content/error/fourzerofour"
import PortfolioLayout from "./content/portfolio/portfolio";

import AcademiaLayout from "./content/academia/academia";
import WhatWeDo from "./content/landing/whatwedo";


function StandardPage(props) {

    return (
        <>
            <TopNav />
            <BaseLayout>
                {props.children}
            </BaseLayout>
            <Footer />
        </>
    )

}

function ErrorPage() {
    return (
        <>
            <TopNav />
            {/* <HeroLayout /> */}
            <BaseLayout>
                <FourZeroFour />
            </BaseLayout>
            <Footer />
        </>
    )
}

const routes = createBrowserRouter([
    {
        path: "/",
        element: <>
            <TopNav />
            <HeroLayout />
            <BaseLayout>
                <Main />
                <Paper sx={{ backgroundColor: 'grey.800' }}>
                    <Grid container padding={4}>
                        <Grid item xs={12}>
                            <PortfolioLayout noFeature heroImageHeight="200px"/>
                        </Grid>
                    </Grid>
                </Paper>
                <Toolbar />
                <WhatWeDo></WhatWeDo>
                {/* <Toolbar /> */}
                <Paper sx={{ backgroundColor: 'grey.800' }}>
                    <Grid container padding={4}>
                        <Grid item xs={12}>
                            <BlogLayout noFeature heroImageHeight="200px"/>
                        </Grid>
                    </Grid>
                </Paper>

            </BaseLayout>

            <Footer />
        </>,
        errorElement: <ErrorPage />
        // children: [
        //     {
        //         path: "/",
        //         element: <Overview />
        //     }
        // ]
        // errorElement: <ErrorPage />,
    },
    {
        path: "/news",
        element:
            <>
                <StandardPage>
                    <Toolbar />
                    <BlogLayout />
                </StandardPage>
            </>,
        errorElement: <ErrorPage />
        // children: [
        //     {
        //         path: "/",
        //         element: <Overview />
        //     }
        // ]
        // errorElement: <ErrorPage />,
    },
    {
        path: "/news/:articleid", //gives a list of cables for that project
        element:
            <>
                <StandardPage>
                    <Toolbar />
                    <BlogLayout />
                </StandardPage>
            </>,
        errorElement: <ErrorPage />
        // errorElement: <ErrorPage />,
    },
    {
        path: "/about", //gives a list of cables for that project
        element:
            <>
                <StandardPage>
                    <AboutLayout />
                </StandardPage>
            </>,
        errorElement: <ErrorPage />
        // errorElement: <ErrorPage />,
    },
    {
        path: "/academia", //gives a list of cables for that project
        element:
            <>
                <StandardPage>
                    <AcademiaLayout />
                </StandardPage>
            </>,
        errorElement: <ErrorPage />
        // errorElement: <ErrorPage />,
    },
    {
        path: "/contact", //gives a list of cables for that project
        element:
            <>
                <StandardPage>
                    <ContactLayout />
                </StandardPage>
            </>,
        errorElement: <ErrorPage />
        // errorElement: <ErrorPage />,
    },
    {
        path: "/portfolio",
        element:
            <>
                <StandardPage>
                    <Toolbar />
                    <PortfolioLayout />
                </StandardPage>
            </>,
        errorElement: <ErrorPage />
    },
    {
        path: "/portfolio/:articleid", //gives a list of cables for that project
        element:
            <>
                <StandardPage>
                    <Toolbar />
                    <PortfolioLayout />
                </StandardPage>
            </>,
        errorElement: <ErrorPage />
    },
    // {
    //   path: "/bleb",
    //   element: <Test arg="herb" />,
    //   // errorElement: <ErrorPage />,
    // },
    // {
    //   path: "/project",
    //   element: <><ProjectBrowser /></>,
    //   errorElement: <ErrorPage />,
    // },
    // {
    //   path: "/project/:projectId", //gives a list of cables for that project
    //   element: <><ProjectDataBrowser /></>,
    //   errorElement: <ErrorPage />,
    // },
    // {
    //   path: "/project/:projectId/cable/:cableIdAndDateQuery", //presents the viewer for that cable
    //   element: <CableDataBrowser />,
    //   errorElement: <ErrorPage />,
    // }
]);


export default routes;
