import { React, useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import {
    Box, AppBar, Typography, IconButton, Toolbar, Paper, Link,
    Divider, ListItem, List, ListItemButton, ListItemText, Button, Drawer, CssBaseline,
    Grid
} from '@mui/material';
// import { MenuIcon } from '@mui/icons-material'

import TopNav from '../../layouts/BaseLayout/SidebarLayout/Header/TopNav'

function HeroLayout(props) {


    const heroImageHeight = "550px";

    const post = {
        title: "Create value from data",
        description: "Bridge the gap between the physical and the digital",
        linkText: "Get in contact",
        image: "images/bg.jpg", //"https://source.unsplash.com/random?wallpapers",
        linkURL: "/contact"
    }

    // https://mui.com/material-ui/customization/breakpoints/
    return (
        <Paper
            sx={{
                minWidth: "315px",
                position: 'relative',
                backgroundColor: 'grey.800',
                color: '#fff',
                mb: 4,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url(${post.image})`
            }}
        >
            {/* Increase the priority of the hero background image */}
            {<img style={{ display: 'none' }} src="https://source.unsplash.com/random?wallpapers" alt="DKGR Data Systems banner image" />}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    backgroundColor: 'rgba(0,0,0,.3)', //fades the image
                    // height: "100px"
                }}
            />
            <Grid container>
                <Grid item xs={12}> {/* set this to 6 for right aligning the text*/}
                    <Box
                        sx={{
                            position: 'relative',
                            p: { xs: 6, md: 6 },
                            pr: { md: 0 },
                            textAlign: "center",
                            height: heroImageHeight,
                            display: "flex", //TO VERTICALL ALIGN TEXT - https://stackoverflow.com/questions/56342496/vertical-align-using-material-ui-paper
                            flexDirection: "column",  //vertical align text
                            justifyContent: "center" //vertical align text
                        }}
                    >
                        <Typography sx={{ fontSize: "4rem" }} component="h1" variant="h3" color="inherit" gutterBottom>
                            {post.title}
                        </Typography>
                        <Typography sx={{ fontSize: "2rem" }} variant="h5" color="inherit" paragraph>
                            {post.description}
                        </Typography>
                        <Link sx={{ fontSize: 20 }} variant="subtitle1" href={post.linkURL}>
                            {post.linkText}
                        </Link>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
};


export default HeroLayout;
