import { Box, Container, Card, CardContent, CardHeader, Typography, Grid, Divider, Paper, Link, Toolbar } from '@mui/material';
// import { Helmet } from 'react-helmet-async';

import { styled } from '@mui/system';
// import Logo from 'src/components/LogoSign';

import PageHeader from '../../components/pageheader'
import PostContent from '../../components/postcontent'

import academiatext from './academiatext.md'
import academiapresentations from './academiapresentations.md'


function AcademiaLayout() {


    const post = {
        title: "Academic & Scientific works",
        description: "A selection of our academic and scientific works, lecture notes and resources",
        linkText: "Contact us",
        image: "images/bg.jpg"
        // image: "https://source.unsplash.com/random?wallpapers"
    }




    return (
        <>
            <Toolbar></Toolbar>
            <PageHeader post={post} />
            <PostContent content={academiatext} />
            <br></br>
            <PostContent content={academiapresentations} />            
        </>
    );
}


export default AcademiaLayout;
