import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import * as Muicon from "@mui/icons-material";
// import Icon from '@mui/material/Icon'

import AcUnit from '@mui/icons-material/AcUnit'



function CapabilityCard(props) {
  const { post } = props;

  const data = props.item;

  return (
    <Grid item xs={12} xl={4}>
      <CardActionArea component="a" href="/contact">
        <Card sx={{
          minHeight: "340px",
          display: 'flex',
          textAlign: "center",
          display: "flex", //TO VERTICALL ALIGN TEXT - https://stackoverflow.com/questions/56342496/vertical-align-using-material-ui-paper
          flexDirection: "column",  //vertical align text
          justifyContent: "center", //vertical align text,
          // maxWidth: "100%"
        }}>
          <CardContent sx={{ flex: 1}}>
            <CardMedia>
              {data.icon}
            </CardMedia>
            <Typography component="h2" variant="h5">
              {data.title}              
            </Typography>
            <br></br>
            {/* <Typography variant="subtitle1" color="text.secondary" paragraph>
              {data.subhead}
            </Typography> */}
            {data.subhead.map((item, i) => (
              <Typography key={i} variant="subtitle1" color="text.secondary" paragraph>
                {item}
              </Typography>
            ))}
            {/* <Typography variant="subtitle1" paragraph>
              {data.description}
            </Typography> */}
          </CardContent>
          {/* <CardMedia
            component="img"
            sx={{
              width: 160,
              display: { xs: 'none', sm: 'block' }
            }}
            image={data.image}
            alt={data.imageLabel}
          /> */}
        </Card>
      </CardActionArea>
    </Grid>
  );
}


export default CapabilityCard;