import { useState } from "react";

import {
  Box, Container, Card, CardContent, CardHeader, Typography,
  Grid, Divider, Paper, Link,
  List, ListItem, ListSubheader, ListItemButton, ListItemIcon,
  Collapse, ListItemText, Toolbar
} from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import SocialProof from "./socialproof";

// import { Helmet } from 'react-helmet-async';

import { styled } from '@mui/system';
// import Logo from 'src/components/LogoSign';

import CapabilityCard from './capabilitycard';
import capabilitycardcontent from './capabilitycardcontent';

import { ThemeProvider } from '@mui/material'; //required to stop complaints about border radius

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


// const OverviewWrapper = styled(Box)(
//     () => `
//     overflow: auto;
//     flex: 1;
//     overflow-x: hidden;
//     align-items: center;
// `
// );

// {{ p: 10, mb: 10, borderRadius: 12 }}
function Main() {

  // const post = {
  //   title: "egeg",
  //   description: "asd",
  //   linkText: "asd",
  //   // image: "https://source.unsplash.com/random?wallpapers"
  //   image: "images/bg.jpg"
  // }
  // {<img style={{ display: 'none' }} src="bg.jpg" alt="Background code image" />}


  const heroImageHeight = "800px"


  const [open, setOpen] = useState([true]);

  const handleClick = (id) => {
    // console.log(id)
    // return;
    var newOpen = [...open];
    newOpen[id] = !open[id];
    console.log(newOpen)
    setOpen(newOpen)
  };

  return (
    <>
      {/* <Paper
        sx={{
          position: 'relative',
          backgroundColor: 'grey.800',
          color: '#fff',
          mb: 4,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${post.image})`,
        }}
      > */}
      {/* Increase the priority of the hero background image */}
      {/* {<img style={{ display: 'none' }} src="https://source.unsplash.com/random?wallpapers" alt="blerp" />}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.3)',
            // height: "100px"
          }}
        /> */}
      {/* <Grid container> */}
      {/* <Grid item xs={12}> set this to 6 for right aligning the text */}
      {/* <Box
              sx={{
                position: 'relative',
                p: { xs: 6, md: 6 },
                pr: { md: 0 },
                textAlign: "center",
                height: heroImageHeight,
                display: "flex", //TO VERTICALL ALIGN TEXT - https://stackoverflow.com/questions/56342496/vertical-align-using-material-ui-paper
                flexDirection: "column",  //vertical align text
                justifyContent: "center" //vertical align text
              }}
            >
              <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                {post.title} s
              </Typography>
              <Typography variant="h5" color="inherit" paragraph>
                {post.description}
              </Typography>
              <Link variant="subtitle1" href="#">
                {post.linkText}
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Paper> */}

      {/* <Paper
        sx={{
          // position: 'relative',
          backgroundColor: 'grey.800',
          color: '#fff',
          mb: 4,
          backgroundSize: 'cover',
          // backgroundRepeat: 'no-repeat',
          // backgroundPosition: 'center',
          // backgroundImage: `url(${post.image})`,
        }}
      > */}
      {/* <Box justifyContent="center"> */}

      {/* <Box>
          <Grid container spacing={2} padding={2}>
            <Grid item xs={8}>
              <Item>xs=8</Item>
            </Grid>
            <Grid item xs={4}>
              <Item>xs=4</Item>
            </Grid>
            <Grid item xs={4}>
              <Item>xs=4</Item>
            </Grid>
            <Grid item xs={8}>
              <Item>xs=8</Item>
            </Grid>
            
          </Grid>
        </Box> */}

      <Toolbar />

      <SocialProof></SocialProof>

      <Toolbar />

    </>
  );
}


//     <Box display="flex" justifyContent="center" py={5} alignItems="center">
//       {/* <Logo /> */}
//     </Box>
//     {/* <Card sx={{ p: 10, mb: 10, borderRadius: 12 }}> */}
//     <Card sx={{ p: 10, mb: 10 }}>
//       {/* <Hero /> */}
//     </Card>

export default Main;
