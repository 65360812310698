import simulatedFallData from './simulated-fall-data.md'

const data = {
    unique: "simulated-fall-data",
    title: "Using simulated data to bootstrap a machine learning pipeline",
    subhead: "3D simulation in Unity to generate data for a fall detection model",
    image: "/images/portfolio/simulated-fall-data/slipsTripsFallsCrop.jpg",    
    imageThumb: "/images/portfolio/simulated-fall-data/snowFall.png",
    imageLabel: "Simulated fall dataarticle",
    link: "/portfolio/simulated-fall-data",
    file: simulatedFallData
}

export default data;