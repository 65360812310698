import { Box, Container, Card, CardContent, CardHeader, Typography, Grid, Divider, Paper, Link, CardActionArea, Toolbar, CardMedia } from '@mui/material';
// import { Helmet } from 'react-helmet-async';

import { styled } from '@mui/system';
// import Logo from 'src/components/LogoSign';

import { ThemeProvider } from '@mui/material'; //required to stop complaints about border radius

import { useLocation } from 'react-router-dom';

import { selectBlogs, selectArticle } from '../../data/blogSlice'

import { useSelector, useDispatch } from 'react-redux'

import PostContent from '../../components/postcontent';
import BigFeaturedPost from '../../components/bigfeaturedpost';
import SmallFeaturedPost from '../../components/smallfeaturedpost';
import DisplayArticle from '../../components/displayarticle';
import DisplayArticleCard from '../../components/displayarticlecard';

import PageHeader from '../../components/pageheader'





function DisplayAllArticles() {
    const articles = useSelector(selectBlogs)

    return (
        <>
            <Grid container spacing={4}>

                {
                    articles ? Object.keys(articles).map((item, index) => {
                        const post = articles[item]
                        // return <PostContent content={item.file}></PostContent>
                        return (
                            <DisplayArticleCard key={index} content={post}></DisplayArticleCard>
                        )
                    }) : <> <Typography>Loading...</Typography></>
                }
            </Grid>
        </>
    )
}


function BlogLayout(props) {

    const location = useLocation();

    const post = {
        title: "News",
        description: "Latest updates",
        linkText: "Contact us",
        // image: "https://source.unsplash.com/random?wallpapers"
        image: "images/bg.jpg"
    }

    const parsedLocation = location.pathname.split("/");

    // const featuredArticleName = "article1"
    const featuredArticleName = "maths-map"

    let articleid = "";
    let noArticleID = true;

    if (parsedLocation.length === 2) {
        //there is no article id
    } else if (parsedLocation.length === 3) {
        articleid = parsedLocation.pop();
        noArticleID = false;
    }

    const article = useSelector(state => {
        if (!noArticleID) {
            return state.blog.articles[articleid];
        } else {
            let retArticle = { ...state.blog.articles[featuredArticleName] };
            retArticle.title = "Featured: " + retArticle.title;
            return retArticle;
        }
    })


    return (
        <>
            {/* <Toolbar /> */}
            <Box>
                <Box>
                    <>{noArticleID ?
                        <>
                            {
                                props.noFeature ? <>
                                    {/* <Box>
                                        <Typography component="h1" variant="h3" color="inherit" sx={{textAlign: "center" }} gutterBottom>
                                            News
                                            </Typography>
                                    </Box> */}
                                    <PageHeader post={post} heroImageHeight={props.heroImageHeight}></PageHeader>
                                </> :
                                    <>
                                        <BigFeaturedPost content={article} />
                                    </>
                            }
                            <DisplayAllArticles />

                        </> :
                        <>
                            <SmallFeaturedPost content={article}></SmallFeaturedPost>
                            <DisplayArticle content={article}></DisplayArticle>
                            <Toolbar />
                            <Typography variant="h4" component="h4">More articles...</Typography>
                            <DisplayAllArticles />
                        </>
                    }
                    </>
                </Box>
            </Box >
        </>
    );
}



export default BlogLayout;
