import { Box, Container, Card, CardContent, CardHeader, Typography, Grid, Divider, Paper, Link, CardActionArea, Toolbar, CardMedia } from '@mui/material';

function InternalContents(props) {
    const content = props.content;
    const height = props.height;
    // backgroundColor: 'rgba(0,0,0,.3)',

    return (
        <>

            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    backgroundColor: 'rgba(0,0,0,.3)',
                    // height: "100px"
                }}
            />
            <Grid container>
                <CardActionArea component="a" href={content.link}>
                    <Grid item xs={12}> {/* set this to 6 for right aligning the text*/}
                        <Box
                            sx={{
                                position: 'relative',
                                p: { xs: 6, md: 6 },
                                pr: { md: 0 },
                                // textAlign: "center",
                                height: height,
                                display: "flex", //TO VERTICALL ALIGN TEXT - https://stackoverflow.com/questions/56342496/vertical-align-using-material-ui-paper
                                flexDirection: "column",  //vertical align text
                                justifyContent: "center" //vertical align text
                            }}
                        // click={()=>{console.log("CLICK"); }}
                        >
                            {/* <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                                {"Featured article"}
                            </Typography> */}
                            <Typography component="h1" variant="h4" color="inherit" gutterBottom>
                                {/* {"Featured: " + content.title} */}
                                {content.title}
                            </Typography>
                            <Typography color="inherit" paragraph>
                                {content.subhead}
                            </Typography>
                            {/* <Link variant="subtitle1" href="#">
                                {content.linkText}
                            </Link> */}
                        </Box>
                    </Grid>
                </CardActionArea>
            </Grid>
        </>
    )
}

// function Cover(){
//     return (
//       <div style={{position: 'relative', width: '200px', height: '100px'}}>
//         <CarIcon style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%'}} />
//       </div>
//     )
//   }

function FeaturedPost(props) {
    // const post = useSelector(state => selectArticle(state, props.url))

    const height = props.height;
    const content = props.content;

    //if icon is defined, dont draw an image
    // content.icon

    // if()

    return (
        <>
            {
                content.icon ? <></> :
                    <Paper
                        sx={{
                            position: 'relative',
                            backgroundColor: 'grey.800',
                            color: '#fff',
                            mb: 4,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundImage: `url(${content.image})`,
                        }}
                    >
                        {/* Increase the priority of the hero background image */}
                        {<img style={{ display: 'none' }} src={content.image} alt={content.imageLabel} />}
                        <InternalContents content={content} />
                    </Paper>
            }
        </>)
}

export default FeaturedPost