import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import { Paper, Toolbar } from '@mui/material'

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'© '}
      <Link color="inherit" href="https://dkgrdatasystems.com/">
        dkgrdatasystems.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Footer(props) {
  //   const { description, title } = props;

  const title = "DKGR Data Systems"
  const description = <Link href="/contact">Get in contact</Link>


  // backgroundColor: 'grey.800',
  // 'background.paper'
  return (
    <>
      <Toolbar />
      <Paper>
        <Box component="footer" sx={{ bgcolor: 'palette.divider', py: 6 }}>
          <Container maxWidth="lg">
            <Typography variant="h6" align="center" gutterBottom>
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              color="text.secondary"
              component="p"
            >
              {description}
            </Typography>
            <Copyright />
          </Container>
        </Box>
      </Paper>
    </>
  );
}

// Footer.propTypes = {
//   description: PropTypes.string.isRequired,
//   title: PropTypes.string.isRequired,
// };

export default Footer;