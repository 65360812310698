import marchingCubes from './marching-cubes.md'

const data = {
    unique: "marching-cubes",
    title: "Marching Cubes with OpenGL",
    subhead: "Rendering volumes with C++",
    image: "/images/2020/marching-cubes/torus-orbit-close.png",
    imageThumb: "/images/2020/marching-cubes/marching-cubes-thumb.png",
    imageLabel: "Marching cubes article",
    link: "/news/marching-cubes",
    file: marchingCubes
}

export default data;