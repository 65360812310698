import wynyardwalk from './wynyard-walk.md'

const data = {
    unique: "wynyard-walk",
    title: "Wynyard Walk Numerical Analysis",
    subhead: "Large scale numerical analysis for a major tunnel",
    image: "/images/portfolio/wynyard-walk/Wynyard_Walk.jpg",    
    imageThumb: "/images/portfolio/wynyard-walk/thumb.png",
    imageLabel: "Wynyard Walk Tunnel article",
    link: "/portfolio/wynyard-walk",
    file: wynyardwalk
}


export default data;