
import { Box, Container, Card, CardContent, CardHeader, Typography, Grid, Divider, Paper, Link } from '@mui/material';

function PageHeader(props) {
    const post = props.post;
    // const post = {
    //     title: "About",
    //     description: "DKGR Data Systems is a London-based technology studio",
    //     linkText: "Contact us",
    //     image: "https://source.unsplash.com/random?wallpapers"
    // }

    const heroImageHeight = props.heroImageHeight ? props.heroImageHeight : "400px";

    return (
        <Paper
            sx={{
                position: 'relative',
                backgroundColor: 'grey.800',
                color: '#fff',
                mb: 4,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url(${post.image})`,
            }}
        >
            {/* Increase the priority of the hero background image */}
            {/* bg.jpg */}

            {/* {<img style={{ display: 'none' }} src="https://source.unsplash.com/random?wallpapers" alt="blerp" />} */}
            {<img style={{ display: 'none' }} src="images/bg.jpg" alt="Background code image" />}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    backgroundColor: 'rgba(0,0,0,.3)',
                    // height: "100px"
                }}
            />
            <Grid container>
                <Grid item xs={12}> {/* set this to 6 for right aligning the text*/}
                    <Box
                        sx={{
                            position: 'relative',
                            p: { xs: 2, md: 2 },
                            pr: { md: 0 },
                            // textAlign: "center",
                            borderRadius: "4px",
                            height: heroImageHeight,
                            display: "flex", //TO VERTICALL ALIGN TEXT - https://stackoverflow.com/questions/56342496/vertical-align-using-material-ui-paper
                            flexDirection: "column",  //vertical align text
                            justifyContent: "center" //vertical align text
                        }}
                    >
                        <Typography component="h1" variant="h4" color="inherit" gutterBottom>
                            {post.title}
                        </Typography>
                        <Typography  color="inherit" paragraph>
                            {post.description}
                        </Typography>
                        <Link variant="subtitle1" href="#">
                            {post.linkText}
                        </Link>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default PageHeader;
