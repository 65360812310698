import gamm2023 from './gamm-2023-accepted.md'

const data = {
    unique: "gamm-2023-accepted",
    title: "Invited to the GAMM2023 Conference",
    subhead: "Bayesian forecasting and anomaly detection",
    image: "/images/2023/gamm-2023-accepted/main2.jpg",
    imageThumb: "/images/2023/gamm-2023-accepted/thumb.jpg",
    imageLabel: "GAMM 2023 accepted article",
    link: "/news/gamm-2023-accepted",
    file: gamm2023
}

export default data;