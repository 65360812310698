import { Box, Container, Card, CardContent, CardHeader, Typography, Grid, Divider, Paper, Link, Toolbar } from '@mui/material';
// import { Helmet } from 'react-helmet-async';

import { styled } from '@mui/system';
// import Logo from 'src/components/LogoSign';

import { ThemeProvider } from '@mui/material'; //required to stop complaints about border radius

import { useLocation } from 'react-router-dom';

import PageHeader from '../../components/pageheader'
import PostContent from '../../components/postcontent'

import abouttext from './contacttext.md'
import { useEffect, useState } from 'react';
import Markdown from '../../components/markdown'


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));



function ContactLayout() {


    const post = {
        title: "Contact",
        description: "DKGR Data Systems is a London-based technology studio",
        linkText: "",
        image: "images/bg.jpg"
        // image: "https://source.unsplash.com/random?wallpapers"
    }




    return (
        <>
            <Toolbar></Toolbar>
            <PageHeader post={post} />
            <PostContent content={abouttext}/>
        </>
    );
}


//     <Box display="flex" justifyContent="center" py={5} alignItems="center">
//       {/* <Logo /> */}
//     </Box>
//     {/* <Card sx={{ p: 10, mb: 10, borderRadius: 12 }}> */}
//     <Card sx={{ p: 10, mb: 10 }}>
//       {/* <Hero /> */}
//     </Card>

export default ContactLayout;
