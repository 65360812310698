import rosehipsag from './rosehips-ag.md'

const data = {
    unique: "rosehips-ag",
    title: "ROSEHIPS Industry Advisory panel member",
    subhead: "ROSEHIPS is a large ESPRC research grant",
    image: "/images/2023/rosehips-ag/DSCF1652_crop-1536x771.jpg",
    imageThumb: "/images/2023/rosehips-ag/rosehipslogoupdatethumb.png",
    imageLabel: "ROSEHIPS article",
    link: "/news/rosehips-ag",
    file: rosehipsag
}

export default data;