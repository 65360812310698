import steamEngine from './steam-engine.md'

const data = {
    unique: "steam-engine",
    title: "Time series data analytics: Steam Engine edition",
    subhead: "A digital display and real time predictive analytics for a model steam engine",
    image: "/images/2020/steam-engine/d22-stationaere-wilesco-dampfmaschinen.jpg",
    imageThumb: "/images/2020/steam-engine/steam-engine-thumb.jpg",
    imageLabel: "Steam engine article",
    link: "/news/steam-engine",
    file: steamEngine
}

export default data;