import { createSlice } from '@reduxjs/toolkit'

// import test1 from '../content/portfolio/articles/test1.md'

import deviceOrientation from '../content/portfolio/articles/device-orientation.js'
import metabaseDataAccess from '../content/portfolio/articles/metabase-data-access.js'
import roboticInspection from '../content/portfolio/articles/robotic-inspection.js'
import wynyardwalk from '../content/portfolio/articles/wynyard-walk.js'
import simulatedFallData from '../content/portfolio/articles/simulated-fall-data.js'
import timeseriesForecastingAnomaly from '../content/portfolio/articles/time-series-forecasting-anomaly.js'


//key is the url
const articles = {}

articles[wynyardwalk.unique] = wynyardwalk;
articles[metabaseDataAccess.unique] = metabaseDataAccess;
articles[simulatedFallData.unique] = simulatedFallData;
articles[deviceOrientation.unique] = deviceOrientation;
articles[roboticInspection.unique] = roboticInspection;
articles[timeseriesForecastingAnomaly.unique] = timeseriesForecastingAnomaly;

export const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState: {
    articles: articles,
  },
  reducers: {

  },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions
export const { } = portfolioSlice.actions


export const selectAllPortfolioItems = (state) => {
    // console.log("WHAT")
    // console.log(state.blog)
    //state.nameofslice.actualdatahere
    return state.portfolio.articles;
}


export const selectPortfolio = (state, url) => {
    // console.log("WHAT")
    // console.log(state.blog)
    //state.nameofslice.actualdatahere
    return state.portfolio.articles[url];
}

export default portfolioSlice.reducer


