// import logo from './logo.svg';
// import './App.css';


import {
  useRoutes,
  createBrowserRouter,
  RouterProvider,
  Route
} from "react-router-dom";

import { Provider } from 'react-redux'
import store from './data/store'


import router from './router';
// import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline, Typography } from '@mui/material';

import { Helmet, HelmetProvider } from 'react-helmet-async';


import { ThemeProvider, createTheme } from '@mui/material/styles';
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
//

function App(props) {

  // const content = useRoutes(router);

  return (
    <HelmetProvider>
      <Helmet>
        <title>DKGR Data Systems</title>
        <meta charset="utf-8"></meta>
        <link rel="canonical" href="https://www.dkgrdatasystems.com/" />

        <base href="/"></base>

        <meta property='og:title' content='DKGR Data Systems'/>
        <meta property='og:image' content='//media.example.com/images/DKGR.png'/>
        <meta property='og:description' content='DKGR Data Systems - Data Science, AI, ML consultancy'/>
        <meta property='og:url' content='//www.dkgrdatasystems.com/'/>

        <link rel="icon" href="/images/favicon.ico"/>
        <link rel="apple-touch-icon" sizes="180x180" href="/images/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon-16x16.png"/>
        {/* link(rel="manifest" href="/images/site.webmanifest") */}
      </Helmet>

      <ThemeProvider theme={darkTheme}>
        <Provider store={store}>
          <CssBaseline />
          <RouterProvider router={router}>
            {/* {content} */}
          </RouterProvider>
        </Provider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
