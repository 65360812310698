import deviceOrientation from './device-orientation.md'

const data = {
    unique: "device-orientation",
    title: "Computing orientation from a wearable accelerometer",
    subhead: "Streaming data via bluetooth to a 3D simulation",
    image: "/images/portfolio/device-orientation/orientationbanner.png",
    imageThumb: "/images/portfolio/device-orientation/orientationthumb.png",
    imageLabel: "Device orientation article",
    link: "/portfolio/device-orientation",
    file: deviceOrientation
}

export default data;